import React, { ReactNode } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import GlobalStyles from '../../themes/global';
import Navbar from '../navbar';

import { MainDefault, MainWide } from './styled';
import {WebsiteFooter} from '../website-footer';

const WebsiteHeader = () => (
  <>
    <Helmet>
      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato&display=swap" /> */}
      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Pirata+One&display=swap" /> */}
      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=UnifrakturCook&display=swap" /> */}
      {/* <link href="https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" /> */}
      {/* <link href="https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" /> */}
      {/* <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap" rel="stylesheet" /> */}
    </Helmet>
    <GlobalStyles />

    <Navbar />
  </>
);

export const LayoutContentBlock = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 0 30px;
  color: #fff;
`;
export const LayoutContentWideBlock = styled.div`
  /* max-width: 1280px; */
  margin: auto;
  padding: 0 30px;
  color: #fff;
`;

export const DefaultLayout: React.FunctionComponent = (props) => (
  <>
    <WebsiteHeader />
    <MainDefault>{props.children}</MainDefault>
    <WebsiteFooter />
  </>
);

export const WideLayout: React.FunctionComponent = (props) => (
  <>
    <WebsiteHeader />
    <MainWide>{props.children}</MainWide>
    <WebsiteFooter />
  </>
);
