import styled from 'styled-components';

const MainCommonStyles = `
  margin: 0 auto;
`;

export const MainDefault = styled.main`
  ${MainCommonStyles};
  max-width: 1280px;
  padding: 0 30px;
  margin-top: 150px;
  margin-bottom: 50px;
`;

export const MainWide = styled.main`
  ${MainCommonStyles};
`;
