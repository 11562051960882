import {FaYoutube, FaTwitter, FaDiscord} from 'react-icons/fa';

interface SocialSiteConfig {
  name: string;
  url: string;
  Icon: React.FunctionComponent;
  color: string;
}

export const socialSites: SocialSiteConfig[] = [
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/channel/UCD4zBy-bxuTdt3UUMowjcaA',
    Icon: FaYoutube,
    color: '#ff0000',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/LukGameDev',
    Icon: FaTwitter,
    color: '#1da1f2',
  },
  {
    name: 'Discord',
    url: 'https://discord.gg/s536z5Y',
    Icon: FaDiscord,
    color: '#7289da',
  },
];