import React from 'react';

import {Wrapper, Content, StyledLink, LogoLink, Menu } from './styled';

const links = [
  {label: 'Home', path: '/'},
  // {label: 'About', path: '/about'},
  // {label: 'Gallery', path: '/gallery'},
  {label: 'Blog', path: '/blog'},
]

const Navbar = () => (
  <Wrapper>
    <Content>
      <div>
        <h1><LogoLink to="/">MadFractaL</LogoLink></h1>
      </div>

      <div>
        <Menu>
          {
            links.map(link => {
              return (
                <li key={link.label}><StyledLink to={link.path}>{link.label}</StyledLink></li>
              );
            })
          }
        </Menu>
      </div>
    </Content>
  </Wrapper>
);

export default Navbar;
