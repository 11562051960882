import styled from 'styled-components';
import { Link } from 'gatsby';

export const Wrapper = styled.nav`
  background-color: rgba(0, 0, 0, 0.9);
  /* padding: 10px 15px; */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Content = styled.div`
  padding: 0 30px;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
  }
`;

const linkHoverColor = 'rgba(255, 255, 255, .1)';

export const LogoLink = styled(Link)`
  color: rgba(255, 255, 255, 0.75);
  font-family: 'UnifrakturCook', serif;
  text-decoration: none;
  display: block;
  padding: .5em .5em;
  font-size: 3em;
  transition: background-color .2s;

  &:hover {
    background-color: ${linkHoverColor};
  }
`;

export const Menu = styled.ul`
  display: flex;
  text-transform: uppercase;
  font-size: 2em;

  li {
    display: flex;
  }
`;

export const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.75);
  font-family: 'Pirata One', serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 .5em;
  transition: background-color .2s;

  &:hover {
    background-color: ${linkHoverColor};
  }
`;