import React from 'react';
import styled from 'styled-components';
import {graphql, Link} from 'gatsby';
import {Helmet} from 'react-helmet';
import {FaYoutube, FaTwitter, FaDiscord} from 'react-icons/fa';

import {socialSites} from '../../common/social-sites';

const FooterBackground = styled.div`
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;
const Content = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 0 30px;
  color: #fff;
`;
const Footer = styled.div`
  color: rgba(255, 255, 255, .5);
  max-width: 540px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  a {
    transition: color .2s;

    &:hover {
      color: rgba(255, 255, 255, .75);
    }
  }
`;
const GameInfoBlock = styled.div`
  > * + * {
    margin-top: 1em;
  }
`;
const GameTitle = styled.span`
  font-family: 'UnifrakturCook', serif;
  font-size: 1.25em;
`;
const FooterSocialSitesList = styled.ul`
  display: flex;

  a {
    font-size: 2em;
    display: block;
  }

  > * + * {
    margin-left: 1em;
  }
`;
const DeveloperInfoBlock = styled.div`
  > * + * {
    margin-top: 1em;
  }
`;

interface Props {}

export const WebsiteFooter: React.FunctionComponent<Props> = (props) => {
  return (
    // <div style={{backgroundColor: '#000', padding: '2em 0'}}>

    <div style={{marginTop: -200}}>
      <div style={{overflow: 'hidden'}}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="9620" height="200" style={{display: 'block', marginLeft: '50%', transform: 'translateX(-50%)'}}>

          <path d="M0,125 L50,118 L100,113 L150,119 L200,107 L250,125 L300,114 L350,133 L400,120 L450,147 L500,104 L550,123 L600,115 L650,136 L700,132 L750,123 L800,141 L850,103 L900,140 L950,130 L1000,103 L1050,112 L1100,121 L1150,149 L1200,103 L1250,141 L1300,126 L1350,118 L1400,136 L1450,142 L1500,127 L1550,134 L1600,102 L1650,115 L1700,112 L1750,105 L1800,125 L1850,112 L1900,119 L1950,136 L2000,112 L2050,125 L2100,133 L2150,113 L2200,118 L2250,137 L2300,116 L2350,125 L2400,121 L2450,128 L2500,114 L2550,123 L2600,127 L2650,134 L2700,136 L2750,117 L2800,131 L2850,117 L2900,135 L2950,118 L3000,125 L3050,137 L3100,112 L3150,117 L3200,133 L3250,114 L3300,142 L3350,126 L3400,118 L3450,114 L3500,116 L3550,125 L3600,146 L3650,135 L3700,135 L3750,129 L3800,124 L3850,134 L3900,121 L3950,135 L4000,136 L4050,138 L4100,124 L4150,114 L4200,125 L4250,118 L4300,123 L4350,116 L4400,135 L4450,130 L4500,121 L4550,103 L4600,135 L4650,125 L4700,131 L4750,146 L4800,117 L4850,124 L4900,125 L4950,136 L5000,115 L5050,125 L5100,138 L5150,124 L5200,129 L5250,137 L5300,135 L5350,116 L5400,137 L5450,118 L5500,102 L5550,106 L5600,125 L5650,117 L5700,136 L5750,130 L5800,118 L5850,143 L5900,105 L5950,104 L6000,134 L6050,101 L6100,144 L6150,126 L6200,142 L6250,113 L6300,131 L6350,119 L6400,125 L6450,124 L6500,137 L6550,124 L6600,114 L6650,149 L6700,122 L6750,109 L6800,114 L6850,116 L6900,124 L6950,132 L7000,136 L7050,147 L7100,126 L7150,126 L7200,135 L7250,129 L7300,120 L7350,105 L7400,124 L7450,132 L7500,119 L7550,102 L7600,126 L7650,124 L7700,133 L7750,136 L7800,119 L7850,114 L7900,134 L7950,125 L8000,112 L8050,116 L8100,103 L8150,133 L8200,124 L8250,109 L8300,125 L8350,117 L8400,137 L8450,133 L8500,135 L8550,121 L8600,122 L8650,113 L8700,125 L8750,125 L8800,112 L8850,124 L8900,116 L8950,123 L9000,118 L9050,135 L9100,129 L9150,126 L9200,135 L9250,125 L9300,120 L9350,112 L9400,134 L9450,124 L9500,137 L9550,130 L9600,102 V200 H0 V125" fill="black" stroke="black" strokeWidth="5"></path>

        </svg>
      </div>

      <FooterBackground
        style={{
          backgroundImage: `url('/images/screenshot_3840x2160_2020-07-19_13-02-47.jpg')`
        }}
      >
        <div style={{background: 'linear-gradient(#000 25%, transparent, hsla(0, 50%, 10%, .5))', paddingBottom: '5em', minHeight: '50em'}}>
          <Content>
            <Footer>
              <GameInfoBlock>
                <div><GameTitle>MadFractaL</GameTitle> © 2019-2020</div>
                <div>
                  <FooterSocialSitesList>
                    {
                      socialSites.map(site => {
                        return (
                          <li key={site.name}>
                            <a href={site.url}>
                              <site.Icon />
                            </a>
                          </li>
                        )
                      })
                    }
                  </FooterSocialSitesList>
                </div>
              </GameInfoBlock>

              <DeveloperInfoBlock>
                <div>Developer: Łukasz Grolik</div>
                <div><a href="https://lukaszgrolik.pl">lukaszgrolik.pl</a></div>
                {/* <div>email</div> */}
              </DeveloperInfoBlock>
            </Footer>
          </Content>
        </div>
      </FooterBackground>
    </div>
  );
};